import { createFromBaseTheme } from "@/style/baseTheme";
import { createTheme, ThemeOptions } from "@mui/material";

import logo from '/images/logo.png'
import logoBar from '/images/logoBar.png'

const customConstants: ThemeOptions = {
  palette: {
    primary: {
      main: "#31c4e5",
    },
    secondary: {
      main: "#020407",
    }
  },
  custom: {
    constants: {
      logoImage: logo,
      logoBarImage: logoBar,
    }
  }
};

const baseCustomizedTheme = createFromBaseTheme(customConstants);

const customizations: ThemeOptions = {}

const newTheme = createTheme(baseCustomizedTheme, customizations);

export default newTheme;
